import { getCookie, decrypt } from '@/helpers';

export default {
  auth(to, from, next) {
    const TOKEN = getCookie('token_vend_sucess');
    if (!TOKEN) {
      next('/login');
      return;
    }
    next();
  },

  payment(to, from, next) {
    const TOKEN = getCookie('token_vend_sucess');
    if (!TOKEN) {
      console.log('Não tem token');
      next('/login');
      return;
    }

    const paymentStatus = decrypt(getCookie('payment_status'));

    if (paymentStatus !== 'approved') {
      if (to.name !== 'PaymentNotApproved') {
        next({ name: 'PaymentNotApproved' });
        return;
      }
    }
    next();
  },
};
